export function useUserSupport() {
  const user = useCurrentUser();

  const memberSupportEmailLink = computed(() => {
    const subject = `Taste Network Support - Sampler ID: ${user.value?.uid}`;
    const encodedSubject = encodeURIComponent(subject);

    return `mailto:support@tastenetwork.io?subject=${encodedSubject}`;
  });

  const brandSupportEmailLink = computed(() => {
    const subject = `Taste Network Brand Support - Brand User ID: ${user.value?.uid}`;
    const encodedSubject = encodeURIComponent(subject);

    return `mailto:support@tastenetwork.io?subject=${encodedSubject}`;
  });

  return {
    memberSupportEmailLink,
    brandSupportEmailLink,
  };
}
